


























































import { Component, Vue } from 'vue-property-decorator'
import { RootObject, Info } from '@/types/document'
import { ElForm } from 'element-ui/types/form'
import Editor from '@/components/editor/Editor.vue'
@Component({
  components: { Editor }
})
export default class Document extends Vue {
  private title = ''
  private documentList: RootObject[] | [] = [
    {
      documentId: null,
      documentTitle: '文档',
      childDocument: []
    }
  ]

  private content = ''

  private props = {
    value: 'documentId',
    label: 'documentTitle',
    children: 'childDocument'
  }

  private info: Info = {
    documentTitle: '',
    documentContent: '',
    documentId: ''
  }

  private updateShow = false
  private loading = false

  private rules = {
    documentTitle: [
      { required: true, message: '请输入文档标题', trigger: ['blur'] }
    ],
    documentContent: [
      { required: true, message: '请输入文档内容', trigger: ['blur'] }
    ]
  }

  private submitShow = false

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.document.selectDocumentByList).then(res => {
      this.documentList[0].childDocument = res || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 拖拽结束
  bodeDrop () {
    this.$axios.post(this.$apis.document.updateDocumentSort, {
      documentList: this.documentList[0].childDocument
    }).then(() => {
      this.getData()
    })
  }

  // 判断节点是否能被拖拽
  allowDrop (draggingNode: any, dropNode: any, type: string) {
    console.log(draggingNode, dropNode, type)
    if (type === 'inner' && dropNode.level >= 3) {
      return false
    } else if (draggingNode.childNodes && draggingNode.childNodes.length !== 0 && dropNode.level >= 3) {
      return false
    } else if (type === 'inner' && draggingNode.childNodes && draggingNode.childNodes.length !== 0 && draggingNode.level <= 2) {
      return false
    } else {
      return true
    }
  }

  // 节点被点击
  nodeClick (data: Info, node: {isLeaf: boolean}) {
    if (!this.updateShow && node.isLeaf) {
      this.info = {
        documentTitle: data.documentTitle,
        documentContent: data.documentContent,
        documentId: data.documentId
      }
    }
  }

  onAdd (data: Info) {
    this.title = '添加文档'
    this.info = {
      documentTitle: '',
      documentContent: '',
      parentDocumentId: data.documentId
    }
    this.content = ''
    this.updateShow = true
  }

  onUpdate (data: Info, node: any) {
    this.title = '编辑文档'
    this.info = {
      documentTitle: data.documentTitle,
      documentContent: data.documentContent,
      documentId: data.documentId
    }
    if (node.isLeaf) {
      this.info.parentDocumentId = node.parent.data.documentId
    }
    this.updateShow = true
  }

  onDelete (data: {documentId: string}) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.document.deleteDocument, {
          documentId: data.documentId
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getData()
          }).finally(() => {
            this.updateShow = false
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 取消按钮
  onCancel () {
    this.updateShow = false;
    (this.$refs.info as ElForm).resetFields()
  }

  onSubmit () {
    this.info.documentContent = (this.$refs.editor as any).content
    const url = this.info.documentId
      ? this.$apis.document.updateDocument
      : this.$apis.document.insertDocument;
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(url, this.info
        ).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.updateShow = false
          this.getData()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
