






import { Component, Vue, Prop } from 'vue-property-decorator'
import VueTinymce from '@packy-tang/vue-tinymce'
import 'tinymce/icons/default/icons'
import { bucket, picDns } from '@/components/uploadFile/config'
import { getUUID } from '@/utils/formatData'
Vue.use(VueTinymce)
interface BlobInfo{
  blob: Function;
}
@Component
export default class Editor1 extends Vue {
  @Prop({ default: '' }) private value!: string
  private content = this.value
  private setting: any= {
    language_url: '/tinymce/langs/zh_CN.js',
    language: 'zh_CN',
    toolbar: 'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | numlist bullist | image table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough indent outdent superscript subscript  removeformat  preview hr help codesample code',
    plugins: 'codesample hr help image imagetools media table lists fullscreen quickbars preview code',
    nonbreaking_force_tab:true,
    branding: false, // 隐藏品牌标识
    height: 560,
    menubar: false, // 最上方menu菜单的显示隐藏
    toolbar_drawer: true,
    // readonly:true,
    // elementpath: false, // 禁用下角的当前标签路径
    // CONFIG: Paste
    paste_retain_style_properties: 'all',
    paste_word_valid_elements: '*[*]', // word需要它
    paste_data_images: true, // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
    paste_convert_word_fake_lists: true, // 插入word文档需要该属性
    paste_webkit_styles: 'all',
    paste_merge_formats: true,
    paste_auto_cleanup_on_paste: false,
    paste_remove_styles_if_webkit: false,
    table_style_by_css: true,
    fix_list_elements:true,
    // CONFIG: Font
    fontsize_formats: '10px 11px 12px 14px 16px 18px 20px 24px',
    images_upload_handler: (blobInfo: BlobInfo, success: Function) => {
      this.upload(blobInfo, (e: string) => {
        success(e)
      })
    }
  }

  upload (blobInfo: BlobInfo, fn: Function) {
    this.$axios.get(this.$apis.common.uploadToken, { bucket }).then((token) => {
      const data = new FormData()
      const date = new Date()
      const key = `admin/${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}/${getUUID()}`
      console.log(blobInfo)
      data.append('file', blobInfo.blob())
      data.append('token', token)
      data.append('key', key)
      this.$axios.post(this.$apis.common.uploadQiniup, data).then(res => {
        fn(picDns + res.key)
      })
    })
  }
}
